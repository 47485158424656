@font-face {
font-family: '__BerlingskeSerif_1f1eb3';
src: url(/_next/static/media/facceb7b4e99153a-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__BerlingskeSerif_Fallback_1f1eb3';src: local("Arial");ascent-override: 89.86%;descent-override: 23.65%;line-gap-override: 0.00%;size-adjust: 105.72%
}.__className_1f1eb3 {font-family: '__BerlingskeSerif_1f1eb3', '__BerlingskeSerif_Fallback_1f1eb3', Georgia, Times New Roman, Times, serif
}

@font-face {
font-family: '__LotaGrotesque_33d198';
src: url(/_next/static/media/80bc0466aa489d16-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__LotaGrotesque_33d198';
src: url(/_next/static/media/59a5e7a3807164aa-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__LotaGrotesque_33d198';
src: url(/_next/static/media/ad8338635cc89a2b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__LotaGrotesque_33d198';
src: url(/_next/static/media/a167834c9774bffc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__LotaGrotesque_33d198';
src: url(/_next/static/media/9e4934acacf0cd8b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: italic;
}@font-face {font-family: '__LotaGrotesque_Fallback_33d198';src: local("Arial");ascent-override: 76.58%;descent-override: 22.36%;line-gap-override: 29.68%;size-adjust: 101.07%
}.__className_33d198 {font-family: '__LotaGrotesque_33d198', '__LotaGrotesque_Fallback_33d198', --apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif
}

@font-face {
font-family: '__JennaSue_b8d10b';
src: url(/_next/static/media/6ab6cec5ac71f9e5-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__JennaSue_Fallback_b8d10b';src: local("Arial");ascent-override: 141.62%;descent-override: 47.21%;line-gap-override: 0.00%;size-adjust: 52.96%
}.__className_b8d10b {font-family: '__JennaSue_b8d10b', '__JennaSue_Fallback_b8d10b'
}

